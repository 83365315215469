import React, { useEffect, useRef, useState } from "react";
import styles from "./crm-signup.module.scss";
import { useTranslation } from "react-i18next";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import i18n from "i18next";

import { constant } from "../../../../../config/constant";
import {
  CRMEnrollmentData,
  CRMMandatoryData,
  Country,
} from "../../../models/models";

// import countryData from "../../../../../../public/json/country-nationality-code-flag.json";
import TGSelection from "../../../../../shared/tg-selection";
import TGIcon from "../../../../../shared/tg-icon";
import TGInput from "../../../../../shared/tg-input";
import TGDateDropdown from "../../../../../shared/tg-date-dropdown";
import TGCheckBox from "../../../../../shared/tg-checkbox";
import TGButtonVariants from "../../../../../shared/tg-button-variants";
import LoginWidget from "../../../../../shared/login-widget";

import useScreenSize from "../../../../../utils/hook/useScreenSize";
import termsConditionsData from "../../../../../../public/json/termsAndConditionsCRM.json";
import { RootState } from "../../../slice/RootReducer";
import {
  getCrmSignUpDataPending,
  clearCrmSignUpResponse,
  getCrmSignUpSocialMediaDataPending,
} from "../../../slice/crmSignUpSlice";
import { formatContact } from "../../../../../utils/helper";
import TGTypeahead from "../../../../../shared/tg-typeahead";
import { fetchCRMresidenceRequest,fetchCRMCountryCodeRequest,fetchCRMnewsletterPreferenceRequest } from "../../../slice/fetchCrmProfileSlice";

const CRMSignUp = () => {
  const [crmEnrollmentData, setCrmEnrollmentData] = useState<CRMEnrollmentData>(
    {
      title: "",
      firstName: "",
      lastName: "",
      dob: "",
      countryCode: "",
      contactNumber: "",
      email: "",
      confirmEmail: "",
      thaiNewsCheck: "N",
      thirdPartyCheck: "N",
      termsConditions: "",
      parentFirstName: "",
      parentLastName: "",
      preference: "",
      country: "", 
      countrycodes: "",
    }
  );
  const [errors, setErrors] = useState<Partial<CRMEnrollmentData>>({});
  const [forceClose, setForceClose] = useState(false);
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] =
    useState<Country | null>(null);
  const [emailRegistered, setEmailRegistered] = useState<boolean>(false);
  const [isPrimaryChecked, setIsPrimaryChecked] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [crmAlreadyExist, setCrmAlreadyExist] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showSignIn, setShowSignIn] = useState(false);
  const [showGuardianConsent, setShowGuardianConsent] =
    useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedPreferedCountry, setSelectedPreferedCountry] =
    useState<Country | null>(null);
  const [selectedCountryValue, setSelectedCountryValue] = useState(null);
  const [selectedCntryValue, setSelectedCntryValue] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [disableEmail, setDisableEmail] = useState(false);
  const [moreThen16, setMoreThen16] = useState(true);
  const [isToggleOpen, setIsToggleOpen] = useState<string>("");
  const [countryCodeResponse, setCountryCodeResponse] = useState<any>([]);
  const [newsletterPreferenceResponse, setNewsletterPreferenceResponse ] = useState<any>([]);
  const [residenceResponse, setResidenceResponse ] = useState<any>([]);
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, type } = location.state || {};
  const termConditionRef = useRef<any>(null);
  const isNewsPromotionCheckedRef = useRef<any>(null);
  const { t } = useTranslation();
  const { clientId, appId } = constant;
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const CrmSignupResponse = useSelector(
    (state: RootState) => state?.osciReducer?.crmSignUp
  );
  const lessThan16 = moment().subtract(16, "years");
  const urlCountry = i18n.language?.split("-")?.[1]?.toUpperCase();

  // const preferenceFromResponse = useSelector(
  //   (state: RootState) => state?.osciReducer?.preferenceFrom.countryList
  // );
  useEffect(() => {
    dispatch(fetchCRMCountryCodeRequest());
    dispatch(fetchCRMnewsletterPreferenceRequest());
    dispatch(fetchCRMresidenceRequest());
}, []);

const crmProfileResponse = useSelector(
  (state: RootState) => state?.osciReducer?.fetchCrmMemberProfile
);

useEffect(() => {
  setCountryCodeResponse(crmProfileResponse?.crmCountryCodeData),
  setNewsletterPreferenceResponse(crmProfileResponse?.crmnewsletterPreferenceData),
  setResidenceResponse(crmProfileResponse?.crmresidenceData)
}, [crmProfileResponse]);

  var res: any;
  var cntry: any;

  useEffect(() => {
    const urlCountry = i18n.language?.split("-")?.[1]?.toUpperCase();
    if (newsletterPreferenceResponse?.length > 0) {
      res = newsletterPreferenceResponse.find(
        (el: { code: string }) => el.code === urlCountry
      );

      let newRes = res?.country?.split("-").pop().trim()
      if (residenceResponse?.length > 0) {
        cntry = residenceResponse.find(
       
          (el: { name: string }) => el.name === newRes
        );
      
        setSelectedCntryValue(cntry);
      }
      setSelectedCountryValue(res);
    }

  
    let socialdata = {};
    if (data && Object.keys(data)?.length > 0 && type) {
      socialdata = {
        firstName: type === "facebook" ? data?.first_name : data?.given_name,
        lastName: type === "facebook" ? data?.last_name : data?.family_name,
        email: data?.email,
        confirmEmail: data?.email,
        preference: res?.country,
        country: cntry?.name,
      };
    }

    setCrmEnrollmentData({
      ...crmEnrollmentData,
      ...socialdata,
      preference: res?.country,
      country: cntry?.name,
    });

    data?.email ? setDisableEmail(true) : setDisableEmail(false);
  }, [newsletterPreferenceResponse,residenceResponse, data, type]);

  useEffect(() => {
    if (CrmSignupResponse.crmStatus.message === "Duplicate") {
      setCrmAlreadyExist(true);
      setErrorMessage("");
    } else if (CrmSignupResponse.crmStatus.message === "GenericError") {
      setErrorMessage(t("label_something_went_wrong"));
      setCrmAlreadyExist(false);
    } else {
      if (CrmSignupResponse.crmStatus.message === "Member Added") {
        setCrmAlreadyExist(false);
        setErrorMessage("");
        setDisableEmail(false);
        navigate(`/${i18n.language}/crmenrollment/success`, {
          state: { type: type, data: data },
        });
        dispatch(clearCrmSignUpResponse());
      }
    }
  }, [CrmSignupResponse, navigate, dispatch]);

  // const setUpdateshowGuardianConsent = (dob: string) => {
  //   const convertedDate = moment(dob, "YYYY-MM-DD");
  //   //const consent: boolean = convertedDate?.isAfter(lessThan16)
  //   setShowGuardianConsent(
  //     convertedDate?.isAfter(lessThan16) &&
  //       (crmEnrollmentData.thirdPartyCheck === "Y" ||
  //         crmEnrollmentData.thaiNewsCheck === "Y")
  //   );
  //   if (showGuardianConsent) {
  //     setErrors({ ...errors, ["parentFirstName"]: "", ["parentLastName"]: "" });
  //   }
  // };

  // useEffect(() => {
  //   setUpdateshowGuardianConsent(crmEnrollmentData.dob);
  // }, [
  //   crmEnrollmentData.dob,
  //   crmEnrollmentData.thirdPartyCheck,
  //   crmEnrollmentData.thaiNewsCheck,
  // ]);

  useEffect(() => {
    if (selectedCountryValue && isPrimaryChecked) {
      setIsDisabled(false);
    }
  }, [selectedCountryValue]);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isEmailMatch = (email: string) => {
    return email !== crmEnrollmentData.email ? false : true;
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setCrmEnrollmentData({ ...crmEnrollmentData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    if (name == "email") {
      setCrmAlreadyExist(false);
    } else if (name == "contactNumber") {
      setErrors({ ...errors, countryCode: "" });
    }
  };

  const setTitleState = (e: any, obj: any) => {
    setCrmEnrollmentData({ ...crmEnrollmentData, title: obj.listName });
    setErrors({ ...errors, title: "" });
  };

  const handleDateChange = (date: string, month: string, year: string) => {
    const formattedDob = `${year}-${month}-${date}`;
    const dobDate = moment(formattedDob, "YYYY-MM-DD");
    const lessThan16 = moment().subtract(16, "years");
    setCrmEnrollmentData({ ...crmEnrollmentData, dob: formattedDob });
    setErrors({ ...errors, dob: "" });
    setDate(date);
    setMonth(month);
    setYear(year);
    dobDate?.isBefore(lessThan16) && setMoreThen16(true);
    dobDate?.isAfter(lessThan16) && setMoreThen16(false);
  };

  const handleCountryCodeChange = (selected: any) => {
    const country = selected.length ? selected[0] : null;
    setSelectedCountryCode(country);
    setCrmEnrollmentData({
      ...crmEnrollmentData,
      countryCode: country?.code,
      countrycodes: country?.countrycodes,
    });
    setErrors({ ...errors, countryCode: "" });
    setIsToggleOpen("");
  };

  const handlePrefCountryChange = (selected: any) => {
    const country = selected.length ? selected[0] : null;
    setSelectedCountryValue(country);
    setErrors({ ...errors, preference: "" });
    setIsToggleOpen("");
  };

  const handleCountryChange = (selected: any) => {
    const country = selected.length ? selected[0] : null;
    setSelectedCntryValue(country);
    setErrors({ ...errors, country: "" });
    setCrmEnrollmentData({
      ...crmEnrollmentData,
      country: "",
    });
    setIsToggleOpen("");
  };

  const checkedPrimaryChange = (e: any): void => {
    setIsPrimaryChecked((prevState: any) => !prevState);
    if (isNewsPromotionCheckedRef.current) {
      const promoCheck = isNewsPromotionCheckedRef.current.checked;
      setCrmEnrollmentData({
        ...crmEnrollmentData,
        thaiNewsCheck: moreThen16 ? (promoCheck ? "Y" : "N") : "N",
      });
      setErrors({ ...errors, thaiNewsCheck: "" });
    }
    // setCrmEnrollmentData({
    //   ...crmEnrollmentData,
    //   thaiNewsCheck: isPrimaryChecked ? "N" : "D",
    // });

    setIsDisabled(isPrimaryChecked);
    if (isPrimaryChecked) {
      setSelectedCountryValue(null);
      setSelectedCntryValue(null);
      setCrmEnrollmentData({
        ...crmEnrollmentData,
        preference: "",
      });
    }
    setErrors({ ...errors, preference: "" });
    setErrors({ ...errors, thaiNewsCheck: "" });
  };

  const checkedChange = (e: any): void => {
    setIsChecked(!isChecked);
    if (termConditionRef.current) {
      const value = termConditionRef.current.checked;
      setCrmEnrollmentData({
        ...crmEnrollmentData,
        termsConditions: value.toString(),
      });
      if (value == true) {
        setErrors({ ...errors, termsConditions: "" });
      } else {
        setErrors({ ...errors, termsConditions: "error" });
      }
    }
  };

  const getFieldLabel = (fieldName: keyof CRMMandatoryData) => {
    const labels: { [key in keyof CRMMandatoryData]: string } = {
      title: t("enroll_title_required"),
      firstName: t("error_reset_first_name_required"),
      lastName: t("error_reset_last_name_required"),
      dob: t("error_reset_dob_required"),
      contactNumber: t("profile_view_contact_err"),
      email: t("signin_email_required"),
      confirmEmail: t("enroll_confirm_email_required"),
      termsConditions: t("enroll_terms_condition"),
      preference: t("label_preferred_news_rop_err_msg"),
      country: t("label_country_of_residence_req"),
    };
    return labels[fieldName];
  };

  const handleSubmitEnroll = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newErrors: Partial<CRMEnrollmentData> = {};
    Object.entries(crmEnrollmentData).forEach(([key, value]) => {
      if (!value?.trim()) {
        newErrors[key as keyof CRMEnrollmentData] = getFieldLabel(
          key as keyof CRMMandatoryData
        );
        // if (key == "parentFirstName" && showGuardianConsent) {
        //   newErrors[key as keyof CRMEnrollmentData] = t(
        //     "error_parent_middle_name_required"
        //   );
        // } else if (key == "parentLastName" && showGuardianConsent) {
        //   newErrors[key as keyof CRMEnrollmentData] = t(
        //     "error_reset_last_name_required"
        //   );
        // }
      } else if (key == "dob") {
        if (date == "") {
          newErrors[key as keyof CRMEnrollmentData] = t("enroll_date_required");
        } else if (month == "00") {
          newErrors[key as keyof CRMEnrollmentData] = t(
            "enroll_month_required"
          );
        } else if (year == "") {
          newErrors[key as keyof CRMEnrollmentData] = t("enroll_year_required");
        }
        if (
          crmEnrollmentData.date !== "" &&
          crmEnrollmentData.month !== "" &&
          crmEnrollmentData.year !== ""
        ) {
          const dobDate = moment(crmEnrollmentData.dob, "YYYY-MM-DD");
          const minAllowedDate = moment().subtract(2, "years");
          var validDate = moment(crmEnrollmentData.dob);
          if (dobDate.isAfter(minAllowedDate)) {
            newErrors[key as keyof CRMEnrollmentData] = t(
              "enroll_age_validation"
            );
          } else if (!validDate.isValid()) {
            newErrors[key as keyof CRMEnrollmentData] = t(
              "label_valid_dob_err"
            );
          }
        }
      } else if (key === "email" && !isValidEmail(value)) {
        newErrors[key as keyof CRMEnrollmentData] = t("reset_invalid_message");
      } else if (
        (key === "firstName" && crmEnrollmentData?.firstName?.length < 2) ||
        (key === "lastName" && crmEnrollmentData?.lastName?.length < 2)
      ) {
        newErrors[key as keyof CRMEnrollmentData] =
          key === "firstName"
            ? t("error_min_length_first_name")
            : t("error_min_length_last_name");
      } else if (
        (key === "parentFirstName" &&
          crmEnrollmentData?.parentFirstName?.length < 2) ||
        (key === "parentLastName" &&
          crmEnrollmentData?.parentLastName?.length < 2)
      ) {
        newErrors[key as keyof CRMEnrollmentData] =
          key === "parentFirstName"
            ? t("error_min_length_first_name")
            : t("error_min_length_last_name");
      } else if (key === "confirmEmail" && !isEmailMatch(value)) {
        if (newErrors["email"] !== t("unlock_Account_invalid_email")) {
          newErrors[key as keyof CRMEnrollmentData] = t(
            "enroll_email_mismatch"
          );
        }
      }

      if (key === "countryCode" && !crmEnrollmentData.countryCode) {
        newErrors[key as keyof CRMEnrollmentData] = t(
          "label_country_code_error"
        );
      }
      if (
        key === "contactNumber" &&
        crmEnrollmentData.contactNumber.length < 3
      ) {
        newErrors[key as keyof CRMEnrollmentData] = t(
          "label_country_code_error"
        );
      }
      if (key === "preference") {
        if (!isDisabled && selectedCountryValue !== null) {
          newErrors[key as keyof CRMEnrollmentData] = t("");
        }
      }
      if (key === "country") {
        if (!isDisabled && selectedCntryValue !== null) {
          newErrors[key as keyof CRMEnrollmentData] = t("");
        }
      }
    });
    setErrors(newErrors);
    const dobDate = moment(crmEnrollmentData.dob, "YYYY-MM-DD");
    const lessThan16 = moment().subtract(16, "years");
    if (crmEnrollmentData.dob != "" && crmEnrollmentData.title != "") {
      const lessThan12 = moment().subtract(12, "years");
      if (
        dobDate?.isAfter(lessThan12) &&
        (crmEnrollmentData.title == t("enroll_mr") ||
          crmEnrollmentData.title == t("enroll_mrs") ||
          crmEnrollmentData.title == t("enroll_ms"))
      ) {
        newErrors["title"] = t("enroll_title_error");
        setErrors({ ...newErrors, title: t("enroll_title_error") });
      }
      if (
        dobDate?.isBefore(lessThan12) &&
        (crmEnrollmentData.title == t("enroll_miss") ||
          crmEnrollmentData.title == t("enroll_mister"))
      ) {
        newErrors["title"] = t("enroll_adult_greater_12");
        setErrors({ ...newErrors, title: t("enroll_adult_greater_12") });
      }
    }
    if (errors.termsConditions == "error") {
      setErrors({ ...newErrors, termsConditions: "error" });
      newErrors["termsConditions"] = "error";
    }

    const keys = Object.keys(newErrors) as (keyof CRMEnrollmentData)[];
    if (!isPrimaryChecked) {
      const index = keys.indexOf("preference", 0);
      if (index > -1) {
        keys.splice(index, 1);
      }
    }

    if (!isPrimaryChecked) {
      const index = keys.indexOf("country", 0);
      if (index > -1) {
        keys.splice(index, 1);
      }
    }

    if (selectedCountryValue !== "" && selectedCountryValue !== null) {
      const index = keys.indexOf("preference", 0);
      if (index > -1) {
        keys.splice(index, 1);
      }
    }

    if (selectedCntryValue !== "" && selectedCntryValue !== null) {
      const index = keys.indexOf("country", 0);
      if (index > -1) {
        keys.splice(index, 1);
      }
    }

    const filterUndefinedError = keys.filter(
      (key) => newErrors[key] !== undefined
    );

    if (type && filterUndefinedError.length === 0) {
      dispatch(
        getCrmSignUpSocialMediaDataPending({
          title: crmEnrollmentData.title,
          firstName: crmEnrollmentData.firstName,
          lastName: crmEnrollmentData.lastName,
          dob: crmEnrollmentData.dob,
          email: crmEnrollmentData.email,
          contactNumber: crmEnrollmentData.contactNumber?.replaceAll("-", ""),
          countryCode:crmEnrollmentData.countrycodes,
          consent: showGuardianConsent,
          parentFirstName: crmEnrollmentData?.parentFirstName,
          parentLastName: crmEnrollmentData?.parentLastName,
          thaiPromoConsent:
            crmEnrollmentData?.thaiNewsCheck === "Y" && moreThen16,
          thaiThirdPartyPromoconsent:
            crmEnrollmentData?.thirdPartyCheck === "Y",
          preferredNews: isPrimaryChecked ? selectedCountryValue?.country : "",
          countryOfResidence: isPrimaryChecked ? selectedCntryValue?.name : "",
          airportCode: isPrimaryChecked
            ? selectedCountryValue?.airportCityCode
            : "",
          socialMediaType: type,
          clientId: type === "facebook" ? data?.id : data?.sub,
        })
      );
    }
    if (!type && filterUndefinedError.length === 0) {
      dispatch(
        getCrmSignUpDataPending({
          title: crmEnrollmentData.title,
          firstName: crmEnrollmentData.firstName,
          lastName: crmEnrollmentData.lastName,
          dob: crmEnrollmentData.dob,
          email: crmEnrollmentData.email,
          contactNumber: crmEnrollmentData.contactNumber?.replaceAll("-", ""),
          countryCode:crmEnrollmentData.countrycodes,
          consent: showGuardianConsent,
          parentFirstName: crmEnrollmentData?.parentFirstName,
          parentLastName: crmEnrollmentData?.parentLastName,
          thaiPromoConsent:
            crmEnrollmentData?.thaiNewsCheck === "Y" && moreThen16,
          thaiThirdPartyPromoconsent:
            crmEnrollmentData?.thirdPartyCheck === "Y",
          preferredNews: isPrimaryChecked ? selectedCountryValue?.country : "",
          countryOfResidence: isPrimaryChecked ? selectedCntryValue?.name : "",
          airportCode: isPrimaryChecked
            ? selectedCountryValue?.airportCityCode
            : "",
        })
      );
    }
  };

  const handleSignInPopupShow = (): void => {
    setShowSignIn(!showSignIn);
  };

  const handleSignInPopupClose = (callback?: Function): void => {
    setShowSignIn(false);
    if (callback) {
      callback();
    }
    navigate(window.location.origin);
  };

  const handleKeyDown = (e: any) => {
    // console.log("test", e.key);
    const isValidInput = /^[a-zA-Z0-9+ ]{0,100}$/.test(e.key);
    if (!isValidInput) {
      e.preventDefault();
    }
  };

  return (
    <div className={styles["crm-tab-content-container"]}>
      <div className={styles["crm-form"]}>
        <div className={styles["personal-information"]}>
          <div className={styles["form-header"]}>
            <div className={styles["form-heading-container"]}>
              <div className={styles["form-heading"]}>
                {t("enroll_personal_Info")}
              </div>
              <div className={styles["form-heading-description"]}>
                {t("enroll_rop_description")}{" "}{t("enroll_age_validation_2_yrs")}
              </div>
            </div>
            {errorMessage.trim() !== "" && (
              <div className={styles.genericErrorContainer}>
                <span className="">
                  <TGIcon icon="error-icon" fillColor="" size="16" />
                </span>
                <div className={styles.genericErrorMessage}>
                  <span>{errorMessage}</span>
                </div>
              </div>
            )}

            {showSignIn && (
              <LoginWidget
                onHidePanel={handleSignInPopupClose}
                loginName={() => {}}
                handlePanelShow={handleSignInPopupShow}
                tabbedName="CRM"
              />
            )}
          </div>
          <div className={styles["form-body"]}>
            <div className={styles["form-fields-row"]}>
              <TGSelection
                isEnroll={true}
                label={t("enroll_title")}
                onChange={handleChange}
                onSelect={(e, ob) => {
                  setTitleState(e, ob);
                }}
                listData={[
                  { id: 1, listName: t("enroll_mr") },
                  { id: 2, listName: t("enroll_mrs") },
                  { id: 3, listName: t("enroll_ms") },
                  { id: 4, listName: t("enroll_mister") },
                  { id: 5, listName: t("enroll_miss") },
                  { id: 6, listName: t("enroll_title_prefer_not") },
                ]}
                name="title"
                isLoading={false}
                value={crmEnrollmentData.title}
                forceClose={forceClose}
                isError={!!errors.title}
                errorLabel={errors.title}
                customClassName={styles["field-width"]}
                isReadOnly={true}
              />
            </div>
            <div className={styles["form-fields-row"]}>
              <TGInput
                isEnroll={true}
                label={t("enroll_firstname")}
                value={crmEnrollmentData.firstName}
                name="firstName"
                maxLength={40}
                onChange={handleChange}
                isError={!!errors.firstName}
                errorLabel={errors.firstName}
                customClassName={styles.fieldContainer}
                validation="name"
              />
              <TGInput
                isEnroll={true}
                label={t("enroll_lastName")}
                value={crmEnrollmentData.lastName}
                name="lastName"
                maxLength={40}
                onChange={handleChange}
                isError={!!errors.lastName}
                errorLabel={errors.lastName}
                customClassName={styles.fieldContainer}
                validation="name"
              />
            </div>
            <div className={styles["form-fields-row"]}>
              <TGDateDropdown
                isEnroll={true}
                label="Date of Birth"
                isError={!!errors.dob}
                onDateChange={handleDateChange}
                errorLabel={errors.dob}
              />
              <div className={styles.contactContainer}>
                <Form.Label className={styles.tgSelectLabel}>
                  {t("profile_view_contact")}
                </Form.Label>
                <Form.Group className={styles.tgFormGroup}>
                  <div className={styles.tgInputGroup}>
                    <div className={styles.countryInput}>
                      <Typeahead
                        id="countryCode"
                        labelKey={(option: any) =>
                          option.countrycodes.toUpperCase()
                        }
                        options={countryCodeResponse}
                        onChange={handleCountryCodeChange}
                        selected={
                          selectedCountryCode ? [selectedCountryCode] : []
                        }
                        open={isToggleOpen === "countryCode"}
                        onBlur={() => setIsToggleOpen("")}
                        renderMenuItemChildren={(option: any) => (
                          <div>
                            <img
                              src={option.image}
                              alt={option.name}
                              style={{ width: "30px", marginRight: "10px" }}
                            ></img>
                            <span>{option.code}</span>
                          </div>
                        )}
                        renderInput={({
                          inputRef,
                          referenceElementRef,
                          ...inputProps
                        }) => (
                          <div className={styles["country-code-select"]}>
                            {
                              <>
                                {selectedCountryCode && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: "10px",
                                      pointerEvents: "none",
                                    }}
                                  >
                                    <img
                                      src={selectedCountryCode?.image}
                                      alt={selectedCountryCode?.name}
                                      style={{
                                        width: "30px",
                                        height: "20px",
                                      }}
                                    ></img>
                                  </div>
                                )}
                                <Form.Control
                                  {...inputProps}
                                  ref={(node: HTMLInputElement) => {
                                    inputRef(node);
                                    referenceElementRef(node);
                                  }}
                                  name="countryCode"
                                  onKeyDown={handleKeyDown}
                                  value={
                                    selectedCountryCode
                                      ? selectedCountryCode?.code
                                      : (inputProps.value?.toUpperCase() as string)
                                  }
                                  style={{
                                    flex: 1,
                                    paddingLeft: selectedCountryCode
                                      ? "40px"
                                      : "12px",
                                  }}
                                  className={`${styles.tgbox} ${
                                    errors.countryCode
                                      ? styles.tgInputContactError
                                      : ""
                                  }`}
                                  placeholder="Country"
                                  onClick={() =>
                                    isToggleOpen === "countryCode"
                                      ? setIsToggleOpen("")
                                      : setIsToggleOpen("countryCode")
                                  }
                                ></Form.Control>
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    pointerEvents: "none",
                                    color: "#000",
                                    fontSize: "16px",
                                  }}
                                >
                                  <TGIcon
                                    icon="chevron-down-icon"
                                    fillColor=""
                                    size="16"
                                  />
                                </div>
                              </>
                            }
                          </div>
                        )}
                      ></Typeahead>
                      <TGInput
                        isEnroll={true}
                        value={formatContact(crmEnrollmentData.contactNumber)}
                        name="contactNumber"
                        maxLength={24}
                        onChange={handleChange}
                        isError={!!errors.contactNumber}
                        customClassName={styles.customMargin}
                        tertiaryCustomClassname={styles.borderContact}
                        placeholder="00-000-0000"
                        validation="contact"
                      />
                    </div>
                    {errors.countryCode && errors.contactNumber ? (
                      <span>
                        {errors.contactNumber && (
                          <Form.Label className={styles.errorLabel}>
                            {t("profile_view_contact_err")}
                          </Form.Label>
                        )}
                      </span>
                    ) : (
                      <span className={styles["error-container"]}>
                        {errors.countryCode && (
                          <Form.Label className={styles.errorLabel}>
                            {t("label_country_code_error")}
                          </Form.Label>
                        )}
                        {errors.contactNumber && (
                          <Form.Label className={styles.errorLabel}>
                            {t("profile_view_contact_err")}
                          </Form.Label>
                        )}
                      </span>
                    )}
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className={styles["form-fields-row"]}>
              <TGInput
                isEnroll={true}
                label={t("label_reset_email")}
                value={crmEnrollmentData.email}
                name="email"
                onChange={handleChange}
                isError={!!errors.email}
                errorLabel={errors.email}
                errorURLLabel={emailRegistered && "Sign in"}
                errorLabelSecondary={
                  emailRegistered && "with your existing account."
                }
                placeholder="Example: THAI@gmail.com"
                disables={disableEmail}
                validation="language"
              />
              {crmAlreadyExist && !isDesktopView && (
                <div className={styles.alreadyExistContainer}>
                  <div className={styles.alreadyExistMessage}>
                    <span>
                      {t("label_enroll_crm_already_reg")}
                      <span
                        className={styles.signInLink}
                        onClick={handleSignInPopupShow}
                      >
                        {t("label_enroll_sign_in")}
                      </span>
                    </span>
                  </div>
                </div>
              )}
              <TGInput
                isEnroll={true}
                label={t("enroll_confirm_email")}
                value={crmEnrollmentData.confirmEmail}
                name="confirmEmail"
                onChange={handleChange}
                isError={!!errors.confirmEmail}
                errorLabel={errors.confirmEmail}
                placeholder="Example: THAI@gmail.com"
                disables={disableEmail}
                validation="language"
              />
            </div>
            {crmAlreadyExist && isDesktopView && (
              <div className={styles.alreadyExistContainer}>
                <div className={styles.alreadyExistMessage}>
                  <span>
                    {t("label_enroll_crm_already_reg")}
                    <span
                      className={styles.signInLink}
                      onClick={handleSignInPopupShow}
                    >
                      {t("label_enroll_sign_in")}
                    </span>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles["marketing-preferences"]}>
          {moreThen16 && (
            <div className={styles.referingEnroll}>
              <div className={styles.heading}>
                {t("label_enroll_marketing_heading")}
              </div>
              <div className={styles.description}>
                {t("label_enroll_marketing_desc")}
              </div>
            </div>
          )}
          {moreThen16 && (
            <div className={styles.agreeContainer}>
              <TGCheckBox
                label={t("label_enroll_news_and_promotions_thai")}
                onChange={checkedPrimaryChange}
                checked={isPrimaryChecked}
                checkref={isNewsPromotionCheckedRef}
                customLabel="checkLabel"
                className="checkLabel"
              />
              {!isDisabled && (
                <div className={styles.preferredNewsDropDownContainer}>
                  <Form.Group
                    style={isDesktopView ? { width: "50%" } : { width: "100%" }}
                  >
                    <Form.Label className={styles.preferredNewsDropDownLabel}>
                      {t("label_preferred_news_rop")}
                    </Form.Label>
                    <TGTypeahead
                      className={styles.wrapper}
                      id="preferredCountry"
                      labelKey={(option: any) => `${option.country}`}
                      options={newsletterPreferenceResponse}
                      onChange={handlePrefCountryChange}
                      isOpen={isToggleOpen === "preferredCountry"}
                      setIsOpen={(id) => setIsToggleOpen(id)}
                      placeholder={
                        selectedCountryValue !== null
                          ? selectedCountryValue?.country?.toUpperCase()
                          : ""
                      }
                      selected={
                        selectedPreferedCountry ? [selectedPreferedCountry] : []
                      }
                      disabled={isDisabled ? true : false}
                    />
                    {errors.preference && !isDisabled && (
                      <Form.Label className={styles.errorLabel}>
                        {t("label_preferred_news_rop_err_msg")}
                      </Form.Label>
                    )}
                  </Form.Group>
                  <Form.Group
                    style={isDesktopView ? { width: "50%" } : { width: "100%" }}
                  >
                    <Form.Label className={styles.preferredNewsDropDownLabel}>
                      {t("label_country_of_residence")}
                    </Form.Label>
                    <TGTypeahead
                      id="country"
                      labelKey={(option: any) => `${option.name}`}
                      options={residenceResponse}
                      onChange={handleCountryChange} 
                      isOpen={isToggleOpen === "country"}
                      setIsOpen={(id) => setIsToggleOpen(id)}
                      placeholder={
                        selectedCntryValue !== null
                          ?  selectedCntryValue?.name?.toUpperCase()
                          : ""
                      }
                      selected={selectedCntryValue ? [selectedCntryValue] : []}
                      showImage={true}
                    />
                    {errors.country && (
                      <Form.Label className={styles.errorLabel}>
                        {t("label_country_of_residence_req")}
                      </Form.Label>
                    )}
                  </Form.Group>
                </div>
              )}
            </div>
          )}

          {isDesktopView ? (
            <div className={styles.stroke}>
              <TGIcon icon="stroke" size=" " fillColor="" />
            </div>
          ) : (
            <div>
              <TGIcon icon="secondary-stroke" size=" " fillColor="" />
            </div>
          )}

          <div className={styles.checkSignup}>
            <TGCheckBox
              isHtmlContent={true}
              htmlContent={termsConditionsData.termsConditionsText}
              onChange={checkedChange}
              checkref={termConditionRef}
              checked={isChecked}
              className="checkLabel"
              customLabel="checkLabel"
            />
            {errors.termsConditions && (
              <div className={styles.termsConditionsError}>
                <TGIcon icon="error-icon" fillColor="" size="16" />
                <div className={styles.termConditionText}>
                  {t("enroll_terms_condition")}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles["submit-container"]}>
          <div>
            <TGButtonVariants
              label={
                CrmSignupResponse.isLoading ? "" : t("button_reset_continue")
              }
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="30px"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              bgColor="#684B9B"
              padding="16px"
              textColor="#FFFFFF"
              fontFamily="Inter"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="160%"
              borderDisabled="0.791px solid #E3D4FE"
              bgColorDisabled="#E3D4FE"
              boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              bgColorHover="#684b9b"
              borderHover="0.791px solid #E3D4FE"
              boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
              height="50px"
              width={isDesktopView ? "170px" : "100%"}
              onClick={handleSubmitEnroll}
              disabled={CrmSignupResponse.isLoading}
            >
              {CrmSignupResponse.isLoading ? (
                <>
                  <Spinner
                    animation="grow"
                    variant="light"
                    style={{ width: "10px", height: "10px", margin: "5px" }}
                  />
                  <Spinner
                    animation="grow"
                    variant="light"
                    style={{ width: "10px", height: "10px", margin: "5px" }}
                  />
                  <Spinner
                    animation="grow"
                    variant="light"
                    style={{ width: "10px", height: "10px", margin: "5px" }}
                  />
                </>
              ) : null}
            </TGButtonVariants>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRMSignUp;
