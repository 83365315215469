// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.puyqKH4Bv0ylcD1pkRD_ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.xa0y2xa3Q5_U0iAHKgYY {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.MiLqizMRZmcMoLvGjJ3i {
  color: var(--gray-text-strong, #2C3059);
  font-family: "Inter-Bold";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  align-self: stretch;
}

.a96BcKSoX57LE1VS9sds {
  color: var(--gray-text, #6A6D87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  align-self: stretch;
}

.PHwxG_xNrBZmhAc0zk8o {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .PHwxG_xNrBZmhAc0zk8o {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/OSCI/src/pages/signup/crm-success/crm-success.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;AACJ;;AAEA;EACI,uCAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gCAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA;AACJ;AACI;EAHJ;IAIQ,WAAA;EAEN;AACF","sourcesContent":[".welcomeContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 12px;\n    align-self: stretch;\n}\n\n.descriptionContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 24px;\n    align-self: stretch;\n}\n\n.heading {\n    color: var(--gray-text-strong, #2C3059);\n    font-family: \"Inter-Bold\";\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 150%;\n    align-self: stretch;\n}\n\n.description {\n    color: var(--gray-text, #6A6D87);\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 160%;\n    align-self: stretch;\n}\n\n.success-container {\n    width: 50%;\n\n    @media screen and (max-width:767px) {\n        width: 100%;\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"welcomeContainer": `puyqKH4Bv0ylcD1pkRD_`,
	"descriptionContainer": `xa0y2xa3Q5_U0iAHKgYY`,
	"heading": `MiLqizMRZmcMoLvGjJ3i`,
	"description": `a96BcKSoX57LE1VS9sds`,
	"success-container": `PHwxG_xNrBZmhAc0zk8o`
};
export default ___CSS_LOADER_EXPORT___;
