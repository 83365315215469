import React, { useState, useEffect } from "react";
import { t } from "i18next";
import styles from "./crm-success.module.scss";
import { SuccessCard } from "../../../../../shared/success-card";
import { ResetUnlockBanner } from "../../../pages/reset-unlock/banner";
import { useLocation } from "react-router-dom";

export const CRMSuccess = () => {
  const [isPanelClosed, setIsPanelClosed] = useState(false);
  const location = useLocation();
  const { type, data } = location.state ?? {};
  const handlePanelShow = (): void => {
    setIsPanelClosed(!isPanelClosed);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ResetUnlockBanner
      imageText={t("label_consent_success_title")}
      descriptionText1={t("label_enrolment_consent_description1")}
      descriptionText2={t("label_enrolment_consent_description2")}
    >
      <div className={styles.welcomeContainer}>
        <div className={styles.heading}>
          {type
            ? t("label_crm_media_heading")
            : t("enroll_crm_success_heading")}
        </div>
        {!type ? (
          <div className={styles.descriptionContainer}>
            <div className={styles.description}>
              {t("enroll_crm_success_thanks")}
            </div>
            <div className={styles.description}>
              {t("enroll_crm_success_spam")}
            </div>
          </div>
        ) : (
          <div className={styles.descriptionContainer}>
            <div className={styles.description}>
              {t("label_crm_media_description")}
            </div>
          </div>
        )}

        <div
          className={`${
            !type ? styles.mobileSuccessContainer : styles["success-container"]
          }`}
        >
          {!type ? (
            <SuccessCard
              iconName="home-icon"
              text="Back to Home Page"
              redirection="route"
              handlePanelShow={handlePanelShow}
            />
          ) : (
            <SuccessCard
              iconName="user-icon"
              text={t("label_signin_header")}
              redirection="route"
              path="crmSocialMedia"
              handlePanelShow={handlePanelShow}
              routeData={data}
              type = {type}
            />
          )}
        </div>
      </div>
    </ResetUnlockBanner>
  );
};
